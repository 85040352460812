.creativegreg_footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-bg);
  padding: 5rem 3rem 1rem 3rem;
}

.creativegreg_footer-btn p {
  box-sizing: border-box;
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 21px;
  color: var(--color-text);
  word-spacing: 2px;
}

.creativegreg_footer-links {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  text-align: left;
}

.creativegreg_footer-links div {
  width: 250px;
}

.creativegreg_footer-links_logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 2;
}

.creativegreg_footer-links_logo img {
  width: 200px;
}

.creativegreg_footer-links_logo p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 14px;
  color: var(--color-text);
}

.creativegreg_footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
}

.creativegreg_footer-links_div h4 {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text);
  margin-bottom: 1rem;
}

.creativegreg_footer-links_div p,
.creativegreg_footer-links_div a {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 14px;
  color: var(--color-text);
  margin: 0.5rem 0;
  cursor: pointer;
}

.creativegreg_footer-copyright {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.creativegreg_footer-copyright p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 14px;
  color: var(--color-text);
}

@media screen and (max-width: 850px) {
  .creativegreg_footer-heading h1 {
    font-size: 44px;
    line-height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .creativegreg_footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .creativegreg_footer-links_logo,
  .creativegreg_footer-links_div {
    justify-content: center;
    align-items: center;
  }

  .creativegreg_footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .creativegreg_footer-links div {
    margin: 1rem 0;
  }
  .creativegreg_footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 400px) {
  .creativegreg_footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }
}
