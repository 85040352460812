.creativegreg_links {
  color: var(--color-text);
  height: calc(100vh - 71px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  font-family: var(--font-family);
}

.creativegreg_links-body {
  display: flex;
  align-items: center;

  padding-top: 5rem;
  flex-direction: column;
}

.creative-link-head {
  position: relative;
}

.creative-link-head img {
  width: 100%;
  display: block;
  border-radius: 50%;
}

.creative-link-head::before {
  content: "";
  position: absolute;
  inset: -12px;
  border: 6px solid var(--color-bg);
  border-top-color: var(--color-text);
  border-bottom-color: #ababab;
  border-radius: 50%;
  z-index: -1;
  animation: spin 5s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.creativegreg_links-body-header {
  font-weight: 800;
  font-size: 60px;
  line-height: 75px;
  font-family: var(--font-family);
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.creativegreg_links-body-alt {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}

.creativegreg_links-body-cards {
  padding-top: 3rem;
  width: 50%;
}

.creativegreg_links-body-cardelement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  background: rgba(242, 242, 242, 0.2);
  border-radius: 5px;

  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;

  height: 65px;
}

.creativegreg_links-body-cardelement a {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-family);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  margin: 0;

  cursor: pointer;
  outline: none;
}

@media screen and (max-width: 700px) {
  .creativegreg_links-body-cards {
    width: 80%;
  }

  .creativegreg_links {
    height: 100%;
  }
}
