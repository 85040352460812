.creativegreg_imggrid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  /* margin-left: -30px;  */
  width: 100%;
  margin-top: 3rem;
}
.creativegreg_imggrid_column {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  background-clip: padding-box;
}

.creativegreg_imggrid_column img {
  background: grey;
  margin-bottom: 1rem;
  cursor: pointer;
}
