.creativegreg_card {
  width: 100%;
  height: 100%;

  background-color: #333;
  border-radius: 10px;
  outline: 1px solid rgb(255, 255, 255, 0.7);
  outline-offset: -12px;
  box-shadow: 3px 3px 10px rgb(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  font-family: var(--font-family);
}

.creativegreg_card-img {
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  transition: transform 0.75s;
}

.creativegreg_card:hover .creativegreg_card-img {
  transform: scale(1.15);
}

.creativegreg_card-title {
  position: absolute;
  left: 20px;
  bottom: 15px;
  font-size: 28px;
  color: rgb(205, 205, 205);
  text-shadow: 0 0 1px rgb(0, 0, 0, 0.5);
}

@media screen and (max-width: 1300px) {
  .creativegreg_card-title {
    font-size: 25px;
  }
}

