.creativegreg_about {
  color: var(--color-text);
  height: calc(100vh - 71px);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family);
}

.creativegreg_about-body {
  margin: 10rem 5rem 5rem 5rem;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: center;
  max-width: 1510px;
}

.creativegreg_about-column {
  width: 100%;
}

.creativegreg_about-body-column-text {
  padding-top: 5rem;
}

.creativegreg_about-body-column-header {
  font-weight: 800;
  font-size: 60px;
  line-height: 75px;
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.creativegreg_about-body-column-alt {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  margin-top: -10px;
}

@media screen and (max-width: 1000px) {
  .creativegreg_about-body {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .creativegreg_about-body {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 3rem;
  }

  .creativegreg_about-body-column,
  .creativegreg_about-body-column-header,
  .creativegreg_about-body-column-alt,
  .creativegreg_about-body-column-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .creativegreg_about-body-column-header {
    font-size: 45px;
    line-height: 55px;
  }

  .creativegreg_about-body-column-alt {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    margin-top: 0px;
  }
}
