@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #4B5D71 35.6%, rgba(255, 196, 156, 0.5) 102.67%);
  --gradient-bar: linear-gradient(270.15deg, rgba(221, 221, 221, 0) -4.33%, rgba(255, 255, 255, 0.12) 112.12%);
  
  --color-bg: #303030;
  --color-button : #F47443;
  --color-blog: #4B5D71;
  --color-text: #f2f2f2;
  --color-subtext: #FF8A71;

  --border-color: #f2f2f280;
}

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap'); */