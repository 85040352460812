.creativegreg_contact {
  color: var(--color-text);
  max-width: 50%;
  margin: auto;
  padding: 2rem 0;
}

.creativegreg_contact h1 {
  font-family: var(--font-family);
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 65px;
  line-height: 75px;
  text-align: center;
  padding-bottom: 3rem;
}

.creativegreg_contact-form {
  display: flex;
  gap: 1.5rem;
}

.creativegreg_contact-form-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 80%;
}

.creativegreg_contact-form-bottom {
  display: flex;
  justify-content: space-between;
  flex: 1 1 80%;
  margin: 2rem 0;
}

.creativegreg_contact-form-bottom button,
.creativegreg_contact-form-response-footer button {
  padding: 0.5rem 1rem;
  color: var(--color-text);
  background: var(--color-button);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;

  width: 174px;
  height: 45px;
}
.creativegreg_contact-form-bottom p {
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  max-width: 50%;
}

.creativegreg_contact-form-column input {
  height: 45px;
}

.creativegreg_contact-form-column textarea {
  height: 100%;
  padding-top: 0.5rem;
}

.creativegreg_contact-form-column input,
.creativegreg_contact-form-column textarea,
.creativegreg_contact-form-response p {
  background: var(--color-blog);
  border-radius: 5px;
  color: var(--color-text);
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  margin-top: 1rem;
  padding-left: 1rem;

  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
}
#creativegreg_contact-form-response > div{
  background: var(--color-blog);
  color: var(--color-text);
  display: flex;
  align-items: center;
  justify-content: center;
}

.creativegreg_contact-form-response-body p{
 margin: 1rem
}

.creativegreg_contact-form-column input::placeholder,
.creativegreg_contact-form-column textarea::placeholder {
  color: var(--border-color);
}

@media screen and (max-width: 3000px) {
  .creativegreg_contact {
    max-width: 50%;
  }
}

@media screen and (max-width: 2200px) {
  .creativegreg_contact {
    max-width: 70%;
  }
}

@media screen and (max-width: 1500px) {
  .creativegreg_contact {
    max-width: 90%;
  }
}

@media screen and (max-width: 1000px) {
  .creativegreg_contact {
    max-width: 90%;
  }
  .creativegreg_contact-form {
    display: flex;
    flex-direction: column;
  }

  .creativegreg_contact-form-column input {
    height: 45px;
    display: block;
  }

  .creativegreg_contact-form-column input,
  .creativegreg_contact-form-column textarea {
    background: var(--color-blog);
    border-radius: 5px;

    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    margin: 0.5rem 0 0 0;
  }

  .creativegreg_contact-form-column textarea {
    height: 150px;
    margin-top: 1rem;
  }

  .creativegreg_contact-form-bottom {
    margin-top: 2rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .creativegreg_contact-form-bottom button {
    margin-bottom: 1rem;
  }

  .creativegreg_contact-form-bottom p {
    text-align: center;
    max-width: 100%;
  }
}

