.creativegreg__navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0rem 2rem;

  border-bottom: 1px solid;
  border-color: var(--border-color);
}

.creativegreg__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.creativegreg__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.creativegreg__navbar-links_container p {
  padding-right: 1rem;
}

.creativegreg__navbar-links_logo {
  flex: 1;
  display: flex;
  justify-content: center;
}

.creativegreg__navbar-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.creativegreg__navbar-links_container p,
.creativegreg__navbar-contact p,
.creativegreg__navbar-menu_container a {
  color: var(--color-text);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.creativegreg__navbar-contact a,
.creativegreg__navbar-menu_container-contact {
  padding: 0.5rem 1rem;
  color: var(--color-text);
  background: var(--color-button);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.creativegreg__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
  align-items: center;
}

.creativegreg__navbar-menu svg {
  cursor: pointer;
}

.creativegreg__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: var(--color-bg);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  box-shadow: 0 0 5 rgp(0, 0, 0, 0.2);
  z-index: 1;
}

.creativegreg__navbar-menu_container p {
  margin: 1rem 0;
}

.creativegreg__navbar-menu_container-contact {
  display: none;
}

@media screen and (max-width: 1050px) {
  .creativegreg__navbar {
    padding: 0rem 2rem;
  }

  .creativegreg__navbar-contact .creativegreg__navbar-menu {
    justify-content: flex-end;
  }
  
  .creativegreg__navbar-links {
    display: none;
  }
  
  .creativegreg__navbar-menu {
    display: flex;
  }
  
  .creativegreg__navbar-links_logo {
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 700px) {
  .creativegreg__navbar {
    padding: 0rem 2rem;
  }
}

@media screen and (max-width: 550px) {
  .creativegreg__navbar {
    padding: 0rem 2rem;
  }
  
  .creativegreg__navbar-contact {
    display: none;
  }
  
  .creativegreg__navbar-menu_container {
    top: 20px;
  }
  
  .creativegreg__navbar-menu_container-contact {
    display: block;
  }
}

@media screen and (max-width: 350px) {
  .creativegreg__navbar {
    padding: 1rem 2rem;
  }

  .creativegreg__navbar-links_logo {
    display: none;
  }
}
