.creativegreg_portfolioPage {
  color: var(--color-text);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  font-family: var(--font-family);
}

.creativegreg_portfolioPage_body {
  margin: 0 4rem;
}

.creativegreg_portfolioPage_body h1 {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 800;
  font-size: 65px;
  line-height: 75px;
  font-family: var(--font-family);
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 4rem;
  padding-top: 5rem;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1000px) {
  .creativegreg_details_body h1 {
    text-align: center;
    font-weight: 700;
    font-size: 45px;
    line-height: 55px;
  }
}

@media screen and (max-width: 800px) {
  .creativegreg_portfolioPage_body h1 {
    font-weight: 600;
    font-size: 45px;
    line-height: 55px;
  }
}
