.welcome_page {
  color: var(--color-text);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: space-between; */
  font-family: var(--font-family);
}

.welcome_page_parallax {
  height: 100vh;
}

.welcome_page_contact {
  position: absolute;
  top: 40px;
  left: 94%;
  transform: translate(-50%, -50%);


  width: 120px;
  padding: 0.5rem 1rem;
  color: var(--color-text);
  background: var(--color-button);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.welcome_page_insideStyle {
  position: absolute;
  padding: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.welcome_page_portfolio {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.welcome_page > h1 {
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 800;
  font-size: 65px;
  line-height: 75px;
  font-family: var(--font-family);
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 4rem;
  padding-top: 5rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.chevron {
  position: absolute;
  width: 2.1rem;
  height: 0.48rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}
.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}
.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}
.chevron:before,
.chevron:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #f2f2f2;
}
.chevron:before {
  left: 0;
  transform: skewY(30deg);
}
.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}
@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

.welcome_page_logo {
  width: 400px;
}

@media screen and (max-width: 1600px) {
  .welcome_page_parallax > div {
    background-position: 22% !important;
  }

  .welcome_page_insideStyle {
    top: 75%;
  }

  .welcome_page_contact {
    position: absolute;
    top: 5%;
    left: 50%;
  }
}
