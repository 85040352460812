.creativegreg_portfolio {
  display: flex;
  flex-direction: column;
  background: var(--color-bg);

  align-items: center;
  padding: 0.5rem 2rem;
}

.creativegreg_portfolio-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.creativegreg_portfolio-title h1 {
  font-weight: 800;
  font-size: 65px;
  line-height: 75px;
  font-family: var(--font-family);
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.creativegreg_portfolio-cards {
  display: flex;
  width: 65%;
  padding: 1rem 2rem;
  gap: 2rem;
}

.creativegreg_card {
  cursor: pointer;
}

.creativegreg_portfolio-cards_column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media screen and (max-width: 1300px) {
  .creativegreg_portfolio-cards {
    padding: 2rem 0;
  }
  .creativegreg_portfolio-cards {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .creativegreg_portfolio-title h1 {
    font-size: 45px;
  }

  .creativegreg_portfolio-cards {
    width: 85%;
  }
}
