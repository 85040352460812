.creativegreg_modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.creativegreg_modal-backdrop img {
  display: block;
  max-width: 80%;
  max-height: 80%;
  margin: 80px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid var(--color-text);
}